<template>
  <div class="builders">
    <v-card flat elevation="3" class="rounded-10">
      <v-card-title>
        <span class="primary--text font-weight-bold fs-25">
          {{ page_title }}
        </span>
        <v-spacer></v-spacer>
        <v-btn
          dark
          color="primary"
          :disabled="btnloading"
          class="text-none mx-1"
          @click="goBack"
        >
          <v-icon left>mdi-reply-all</v-icon>
          Back
        </v-btn>
        <v-btn
          dark
          color="primary"
          :loading="btnloading"
          class="text-none mx-1"
          :disabled="html.length < 20"
          @click="editItem ? update() : save()"
        >
          {{ save_label }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col :cols="mdAndUp ? (expand_view ? 3 : 6) : 12" id="left-side">
            <label class="primary--text subtitle-2">
              Signature Name (Required)
            </label>
            <v-text-field
              :rules="nameRules"
              v-model="title"
              clearable
              clear-icon="mdi-close-circle-outline"
              outlined
              dense
              hide-details="auto"
              class="mb-2"
              placeholder="Required"
            ></v-text-field>
            <v-row dense class="mb-1">
              <v-col>
                <v-btn
                  text
                  outlined
                  class="text-none mr-1 mb-1"
                  @click="showGuide = true"
                >
                  <v-icon color="red" left>mdi-cloud-question</v-icon>
                  Guide & Rules
                </v-btn>
                <v-menu bottom right offset-y>
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                          text
                          outlined
                          class="text-none mr-1 mb-1"
                          v-bind="attrs"
                          v-on="{ ...tooltip, ...menu }"
                        >
                          <v-icon left>
                            mdi-dots-horizontal-circle-outline
                          </v-icon>
                          Upload or Clone Signature
                        </v-btn>
                      </template>
                      <span>Upload or Clone existing signatures</span>
                    </v-tooltip>
                  </template>
                  <v-list dense>
                    <v-list-item @click="$refs.inputfile.click()">
                      <v-list-item-icon>
                        <v-icon color="green">mdi-cloud-upload-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Upload file</v-list-item-title>
                    </v-list-item>
                    <v-divider />
                    <v-subheader>
                      <v-icon color="red" left>mdi-content-duplicate</v-icon>
                      Clone from below existing signatures
                    </v-subheader>
                    <v-list-item v-if="signatures.length < 1">
                      <v-list-item-title class="text-center subtitle-1 py-4">
                        No signatures yet!
                      </v-list-item-title>
                    </v-list-item>
                    <template v-else>
                      <v-list-item
                        v-for="signature in signatures"
                        :key="signature.id"
                        @click="cloneFrom(signature)"
                      >
                        <v-list-item-title>
                          {{ signature.name | ucwords }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
              </v-col>
              <input
                type="file"
                @change="getFile"
                ref="inputfile"
                accept=".txt,.html,.htm"
                style="display: none"
              />
            </v-row>
            <label class="primary--text subtitle-2">
              Signature HTML (Required)
            </label>
            <v-textarea
              class="mb-1"
              hide-details="auto"
              outlined
              :rules="requiredRules"
              rows="35" 
              auto-grow
              clearable
              dense style="max-height: 1000px; overflow-y: auto"
              clear-icon="mdi-close-circle-outline"
              @click:clear="html = ''"
              v-model="html"
              placeholder="<div style='width:100%;'>...</div>"
            >
            </v-textarea>
          </v-col>
          <v-col :cols="mdAndUp ? (expand_view ? 9 : 6) : 12">
            <v-btn
              v-if="html"
              color="primary"
              class="expand-view"
              @click="expand_view = !expand_view"
              fab
              x-small
            >
              <v-icon>
                {{
                  !expand_view
                    ? "mdi-arrow-expand-left"
                    : "mdi-arrow-expand-right"
                }}
              </v-icon>
            </v-btn>
            <HtmlPreviewer v-model="html"></HtmlPreviewer>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pb-5">
        <v-spacer></v-spacer>
        <v-btn
          dark
          color="primary"
          :disabled="btnloading"
          class="text-none mx-1"
          @click="$router.go(-1)"
        >
          <v-icon left>mdi-reply-all</v-icon>
          Back
        </v-btn>
        <v-btn
          dark
          color="primary"
          :loading="btnloading"
          class="text-none mx-1"
          :disabled="html.length < 20"
          @click="editItem ? update() : save()"
        >
          {{ save_label }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card> 
    <SignatureMakerGuide v-model="showGuide"></SignatureMakerGuide>
  </div>
</template>

<script>
import Editor from "@/common/Editor/Editor.vue";
import request from "@/services/axios_instance";
import SignatureMakerGuide from "@/modules/Builders/Guides/EmailSignatureGuide.vue";
import HtmlPreviewer from "./components/HtmlPreviewer.vue";

export default {
  name: "SignatureMaker",
  components: { Editor, SignatureMakerGuide, HtmlPreviewer },
  data: () => ({
    editorKey: 0,
    signatures: [],
    expand_view: false,
    html: "",
    title: null,
    btnloading: false,
    showGuide: false,
    placeholders: [
      "target_first_name",
      "target_last_name",
      "target_fullname",
      "target_organization",
      "target_email",
      "from_website",
      "from_organization",
    ],
    nameRules: [
      (v) => !!v || "Signature name is required!",
      (v) => (v && v.length > 2) || "Min characters is 3",
      (v) => (v && v.length < 61) || "Max characters is 60",
    ],
    requiredRules: [(v) => !!v || "Signature HTML is required"],
    paths: [
      {
        text: "Dashboard",
        disabled: false,
        route: { name: "default-content" },
      },
      { text: "Builders", disabled: true, route: null },
      { text: "Signature", disabled: true, route: null },
    ],
    editItem: null,
    redirect_back: "email-accessories",
  }),
  watch: {
    "$route.params.id": {
      handler: function (val) {
        if (val) {
          this.fetchEmailSignature(val);
        }
      },
      immediate: true,
    },
    "$route.query.redirect_back": {
      handler: function (val) {
        this.redirect_back = val ? val : "email-accessories";
      },
      immediate: true,
    },
    editItem: {
      handler: function (val) {
        if (val) {
          this.html = val.content;
          this.title = val.name;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.$event.$emit("path-change", this.paths);
    this.fetchEmailSignatures();
  },
  computed: {
    page_title() {
      return this.editItem ? "Update Email Signature" : "Build Email Signature";
    },
    save_label() {
      return this.editItem ? "Update Email Signature" : "Save Email Signature";
    },
  },
  methods: {
    fetchEmailSignature(id) {
      request.get(`api/emails/accessories/signature/${id}`).then(({ data }) => {
        this.editItem = data;
      });
    },
    fetchEmailSignatures() {
      request.get(`api/emails/accessories/signature`).then(({ data }) => {
        this.signatures = data;
      });
    },
    appendPlaceholder(value) {
      this.mapping.message = this.mapping.message
        ? `${this.mapping.message} [${value}]`
        : `[${value}]`;
      this.editorKey++;
    },
    save() {
      if (!this.title) {
         this.appSnackbar(
          "Signature title is required",
          "error"
        );
        return false;
      }
      if (!this.html || this.html.length < 6) {
        this.appSnackbar( "Invalid HTML signature", "error");
        return false;
      }
      this.btnloading = true;
      request
        .post(`api/emails/accessories`, {
          title: this.title,
          html: this.html,
          type: "signature",
        })
        .then(({ data }) => {
          this.appSnackbar( "Signature successfully created!");
          this.$router.push({ path: "/dashboard/campaigns/email" });
        })
        .finally(() => {
          this.btnloading = false;
        });
    },
    update() {
      if (!this.title) {
         this.appSnackbar(
          "Signature title is required",
          "error"
        );
        return false;
      }
      if (!this.html || this.html.length < 6) {
        this.appSnackbar( "Invalid HTML signature", "error");
        return false;
      }
      this.btnloading = true;
      request
        .put(`api/emails/accessories/${this.editItem.id}`, {
          title: this.title,
          html: this.html,
        })
        .then(({ data }) => {
          this.appSnackbar( "Signature successfully updated!");
          this.$router.push({
            name: "email-accessories",
            params: { type: "signatures" },
          });
        })
        .finally(() => {
          this.btnloading = false;
        });
    },
    getFile(event) {
      const input = event.target;
      if ("files" in input && input.files.length > 0) {
        this.placeFileContent(
          document.getElementById("content-target"),
          input.files[0]
        );
      }
    },
    placeFileContent(target, file) {
      this.readFileContent(file)
        .then((content) => {
          this.html = content;
        })
        .catch((error) => console.log(error));
    },
    readFileContent(file) {
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onload = (event) => resolve(event.target.result);
        reader.onerror = (error) => reject(error);
        reader.readAsText(file);
      });
    },
    cloneFrom(signature) {
      if (signature.hasOwnProperty("content")) {
        this.html = signature.content;
      } else {
         this.appSnackbar(
          "Signature has no valid HTML contents",
          "error"
        );
      }
    },
    goBack() {
      if (this.$route.query.hasOwnProperty("redirect_uri"))
        this.$router.push(this.$route.query.redirect_uri);
      else this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="scss">
 

.iframe {
  width: 100% !important;
  min-height: 98vh;
  border: none;
}

.expand-view {
  position: absolute;
  right: 0;
  margin-top: 5px;
}
</style>